<template>
  <div class="outside-enter-freely mt-5">
    <v-form ref="caculationFormData" @submit.prevent="submitHandler">
      <div class="power-item mb-12">
      <div class="outside-lable">{{ $t("casbee.label_electricity_emission") }}</div>
      <div class="outside-description">{{ $t("casbee.description_change_emission_factor") }}</div>
      <div class="number-power">
        <casbee-input
          class="factor-input mt-0 mb-0"
          v-model="numberOfPower"
          :value="numberOfPower"
          :label="'t-CO2/kWh'"
          :disabled="isViewMode"
          :errorMessage="inputErrors.numberOfPower"
          @removeError="inputErrors.numberOfPower = ''"
        />
      </div>
    </div>
      <div class="energy-item">
        <div class="outside-lable">{{ $t("casbee.label_enegy_consumption") }}</div>
        <div class="outside-description">{{ $t("casbee.description_input_enegy_consumption") }}</div>
        <div class="energy">
          <casbee-input
            class="factor-input mt-0 mb-0"
            v-model="energyConsumption1m2"
            :value="energyConsumption1m2"
            :label="labelMjPerYear"
            :width="'80'"
            :disabled="isViewMode"
            :errorMessage="inputErrors.energyConsumption1m2"
            @removeError="inputErrors.energyConsumption1m2 = ''"
          />
      </div>
    </div>
    </v-form>
    <common-button
    v-if="!isViewMode"
      class="casbee-btn mt-12"
      v-ripple="false"
      type="colored"
      :isDisable="isViewMode"
      :label="$t('casbee.button_calc_value_inputed')"
      @action="handlerCalculationData()"
    />
  </div>
</template>
<script>
import CasbeeInput from '@/components/casbee/input';
import CommonButton from "@/components/utils/button.vue";
import { validateInputFieldCasbee } from '@/utils/validate';
import { CASBEE_TYPE } from '@/constants/casbee/';
import { PERPOSE_USING_BUILDING, DATA_FOR_CALCULATION, VALUE_CALCULATION_DEFAULT } from '@/constants/casbee/outside';
const math = require('mathjs');
export default {
  components: { CommonButton, CasbeeInput },
  props: {
    detailData: {
      type: Object,
      default: () => {},
    },
    perposeUsingBuildingModel: {
      type: String,
      default: () => '',
    }
  },
  data() {
    return {
      energyConsumption1m2: 0,
      numberOfPower: 0.00043,
      perposeUsingBuildingList: Object.keys(PERPOSE_USING_BUILDING).map((item) => {
        return { name: PERPOSE_USING_BUILDING[item], value: PERPOSE_USING_BUILDING[item]};
      }),
      calcData: {
        total: null,
        source: '',
        patternType: CASBEE_TYPE.ARCHITECTURE_OUTSIDE_ENTER_FREELY,
        label: this.$t("casbee.description_emission_co2_per_m2"),
        labelSource: this.$t("casbee.description_source")
      },
      inputErrors: {
        numberOfPower: '',
        energyConsumption1m2: '',
      },
    };
  },
  mounted() {
    this.prepareDetailData();
  },
  computed: {
    isValidData() {
      return !(Object.values(this.inputErrors).find(item => item !== ''));
    },
    isViewMode () {
      return this.detailData.hasOwnProperty('dataDetail');
    },
    labelMjPerYear() {
      return this.$t('casbee.label_mj_per_year')
    }
  },
  methods: {
    handlerCalculationData() {
      Object.keys(this.inputErrors).forEach((key) => {
        this.inputErrors[key] = validateInputFieldCasbee(this[key]);
      });

      if (this.isValidData) {
        let dataToCalculation = DATA_FOR_CALCULATION.find((item) => item.type === this.perposeUsingBuildingModel);

        let newEnergyConsumption1m2 = math.bignumber(this.energyConsumption1m2);
        let newNumberOfPower = math.bignumber(this.numberOfPower);
        let numberOfPowerPart = math.divide(newNumberOfPower, VALUE_CALCULATION_DEFAULT.CALC_ELECTRIC);
        let numberOfPowerPartDivided = math.multiply(numberOfPowerPart, VALUE_CALCULATION_DEFAULT.CALC_TOTAL);

        let totalElectric = math.multiply(newEnergyConsumption1m2, dataToCalculation.electricity / 100, numberOfPowerPartDivided);

        let totalGas = math.multiply(newEnergyConsumption1m2, dataToCalculation.gas / 100, VALUE_CALCULATION_DEFAULT.CALC_GAS);
        let totalOthers = math.multiply(newEnergyConsumption1m2, dataToCalculation.others / 100, VALUE_CALCULATION_DEFAULT.CALC_OTHERS);

        let totalResult = math.add(totalElectric, totalGas, totalOthers);
        totalResult = math.divide(totalResult, VALUE_CALCULATION_DEFAULT.CALC_TOTAL).toFixed(4).toString();
        const perposeUsingBuildingModel = this.perposeUsingBuildingModel;
        let source = this.$t("casbee.input_field_source_text_outside_enter_freely", { perposeUsingBuildingModel});

        let data = {
          ...this.calcData,
          source: source,
          total: totalResult,
          content_json: {
            perposeUsingBuildingModel: this.perposeUsingBuildingModel,
            energyConsumption1m2: this.energyConsumption1m2,
            numberOfPower: this.numberOfPower
          }
        }
        this.$emit('onHandlerCalculationData', data);
      } else {
        Object.keys(this.inputErrors).forEach((key) => {
          if(Number(this[key]) < 0 || isNaN(Number(this[key]))) {
            this[key] = null;
          }
          return this[key]
        });
      }
    },
    prepareDetailData() {
      if (Object.keys(this.detailData).length > 0) {
        const detailData = this.detailData.dataDetail.content_json;
        Object.keys(detailData).forEach(key => {
          this[key] = detailData[key];
        })
        this.handlerCalculationData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.outside-enter-freely {
  .outside-lable {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    color: $monoBlack;
  }
  .outside-description {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 15px;
    color: $monoBlack;
    line-height: 28px;
    letter-spacing: 0.05em;
  }
  .power-item {
    .number-power {
      display: flex;
      align-items: center;
      gap: 8px;
      &-input {
        width: 80px;
        max-width: 80px;
      }
    }
  }
  .energy-item {
    .energy {
      display: flex;
      align-items: center;
      gap: 8px;
      &-input {
        width: 80px;
        max-width: 80px;
      }
    }
  }
  .casbee-btn {
    width: 100%;
  }
}
@include desktop {
  .outside-enter-freely {
    .outside-lable {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.05em;
      margin-bottom: 12px;
    }
    .outside-description {
      margin-bottom: 12px;
      line-height: 20px;
      letter-spacing: unset;
      font-weight: 400;
      font-size: 14px;
    }
    .power-item {
      margin-top: 48px;
    }
    .casbee-btn {
      // width: 240px;
      width: max-content;
    }
  }
  .casbee-btn {
    // width: 240px;
    width: max-content;
  }
}
</style>
